import "./L2TxLogCard.css";
import { memo, useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkName } from "../../generated/graphql";
import Typography from "@mui/material/Typography";
import jsonParseRawContent from "utils/jsonParseRawContent";
import { RawContentData } from "utils/types";
import findRawDataElement from "utils/findRawDataElement";
import createRawContentState from "utils/createRawContentState";
import handleTxIds from "utils/handleTxIds";
import {
  L2TxLogMuiCardContent,
  L2TxLogDivider,
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import { FullTxLogFragment, ContactExecutedGql } from "../../generated/graphql";
import { FullTxLogFragment as devFullTxLogFragment } from "../../generated/graphql-devnet";
import RawContentModal from "../Modals/RawContentModal";
import RawContentVisializerModal from "../ModalsVisializer/RawContentVisializerModal";
import { BlockChains } from "utils/constants";
import TxContactExecutedSection from "./TxContactExecutedSection";
import TxLoadedSection from "./TxLoadedSection";
import LegacyCwebTxLoadedSection from "./LegacyCwebTxLoadedSection";
import TxLogErrorSection from "./TxLogErrorSection";
import { Chip, Fade, styled, Tooltip } from "@mui/material";
import CopyIcon from "components/CopyIcon";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import { WithCopy } from "shared/components";
import { useQueries } from "@tanstack/react-query";
import { getBlockByHeight, getLastBlock } from "utils/queryRequests";
import CardAdditionalMenu from './CardAdditionalMenu';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type L2TxLogCardProps = {
  title?: string;
  data: FullTxLogFragment | devFullTxLogFragment;
  width: any;
  setNetwork: (n: NetworkName | undefined) => void;
};

const L2TxLogCard = (props: L2TxLogCardProps) => {
  const { title, data, width, setNetwork } = props;
  const blockchain = BlockChains[data.network];
  const [rawTxLoaded, setRawTxLoaded] = useState("");
  const [rawContextJsonObj, setRawContextJsonObj] = useState<RawContentData>();
  const [rawContext, setRawContext] = useState(false);
  const [rawContextVisualiser, setRawContextVisualiser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alias, setAlias] = useState<string | undefined>(undefined);
  const { contractTemplates, interpreters, contractInstance } =
    useGlobalIndex();
  const handleClose = () => {
    setRawContext(false);
  };
  const handleCloseVisializer = () => {
    setRawContextVisualiser(false);
    const newSearchParams = new URLSearchParams(searchParams);

    if (newSearchParams.has("visualizer")) {
      newSearchParams.delete("visualizer"); // Remove the parameter if value is undefined
    }
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (data?.network) {
      setNetwork(data?.network as unknown as NetworkName);
    }
  }, [data, setNetwork]);
  useEffect(() => {
    if (searchParams.get("visualizer") === "true") {
      setRawContextVisualiser(true);
    }
  }, [searchParams]);
  useEffect(() => {
    if (data?.rawContent) {
      setRawTxLoaded(JSON.stringify(data.rawContent));
      const jsonRaw = jsonParseRawContent(JSON.stringify(data.rawContent));
      let jsonrawObj = createRawContentState(jsonRaw);
      setRawContextJsonObj(jsonrawObj);
    }
  }, [data?.rawContent]);

  useEffect(() => {
    if (data?.txIssuer) {
      const issuerAlias = checkIssuerAlias(
        data?.txIssuer,
        interpreters,
        contractTemplates,
        contractInstance
      );
      if (issuerAlias) setAlias(issuerAlias);
    }
  }, [data?.txIssuer, contractTemplates, interpreters]);

  const block = useQueries({
    queries: [{
        queryKey: ["block-by-height", props.data.blockHeight, props.data.network],
        queryFn: () => getBlockByHeight(props.data.blockHeight, props.data.network),
        enabled: !!props.data.blockHeight && !!props.data.network,
        staleTime: 1000 * 60 * 60,
      }]
  });
  
  const lastBlockData = useQueries({
    queries: [{
        queryKey: ["last-block", props.data.network],
        queryFn: () => getLastBlock(props.data.network),
        enabled: !!props.data.network,
        staleTime: 1000 * 60 * 60,
      }]
  });

  const lastBlock = useMemo(() => {
    const blockForSearch = (lastBlockData?.[0]?.data as {lastBlocks?: Array<{height: number}>})?.lastBlocks?.[0];

    return blockForSearch;
  }, [lastBlockData])

  const additionalMenuItems = [
    {
      label: 'Raw Content',
      onClick: () => setRawContext(true)
    },
    {
      label: 'Visualize Transaction',
      onClick: () => {
        setRawContextVisualiser(true);
        const newSearchParams = new URLSearchParams(
          searchParams
        );

        // Append the new search param
        newSearchParams.append(
          "visualizer",
          "true"
        );

        // Set the new search params
        setSearchParams(newSearchParams);
      }
    }
  ];

  const confirmations = useMemo(() => {
    if (!lastBlock) return undefined;
    return lastBlock.height - data.blockHeight
  }, [lastBlock, data.blockHeight])

  console.log('rawContextJsonObj', rawContextJsonObj, data?.txLogInfo);

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100 }}>
        <L2TxLogMuiCardContent sx={{ minWidth: 100 }}>
          <div
            className="L2TxLogInfo-container"
            style={{ textIndent: "0px !important" }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <div className="LogContent-inner" style={{ height: "100%" }}>
                <div className="Center-container">
                  <div
                    className={
                      width > MOBILE_WIDTH ? "Center" : "Center-mobile"
                    }
                    style={{
                      position: "relative",
                    }}
                  >
                    <div id="L2TxLogCard-header">
                      {title && (
                        <Typography className="BoldFont16" align="center">
                          {title}
                        </Typography>
                      )}
                    </div>
                    <div className="L2TxLogCard-data-container">
                      <Row justifyBetween>
                        <Row>
                          <div>
                            <img
                              style={{ marginTop: "10px" }}
                              id="Block-icon"
                              src={blockchain.icon}
                              alt="bc"
                            />
                            <Typography className="BoldFont16">
                              {blockchain.networkName}
                            </Typography>
                          </div>
                          <div>
                          <Typography className="BoldFont16" align="left">
                            Transaction ID
                          </Typography>
                          <div className="L2TxId-row">
                            <Typography
                              className="BoldFont14 L2TxId-container"
                              align="center"
                            >
                              {data.txid ? (
                                <>
                                  <Tooltip
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          width: "min-content !important",
                                          backgroundColor: "#4f4f72 !important",
                                          padding: "0px !important",
                                          marginTop: "-1px !important",
                                        },
                                      },
                                    }}
                                    disableFocusListener
                                    TransitionComponent={Fade}
                                    title={
                                      <div id="TxId-tooltip">{data.txid}</div>
                                    }
                                  >
                                    <div
                                      onClick={() => {
                                        setSearchParams({ search: data.txid });
                                      }}
                                      className="L2TxId-container"
                                    >
                                      {handleTxIds(data.txid)}
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </Typography>
                            <CopyIcon data={data.txid} />
                          </div>
                        </div>
                        </Row>
                        {rawTxLoaded && 
                          <CardAdditionalMenu items={additionalMenuItems} />
                        }
                      </Row>
                      <Row margin="1rem 0">
                        <Typography className="BoldFont16" align="center">
                          L2 Block Height 
                        </Typography>
                        <WithCopy copyText={data.blockHeight}>
                          <Chip label={`#${data.blockHeight}`} color="info"/>
                        </WithCopy>
                        {typeof confirmations === 'number' &&
                          <Chip 
                          label={
                              <Row>
                                {confirmations === 0 
                                  ? <>Confirming...</> 
                                  : <>
                                      <>{confirmations} Block Confirmations</>
                                      <CheckCircleIcon/>
                                    </>
                                }
                              </Row>
                            } 
                          color={confirmations === 0 ? 'warning' : 'success'} 
                          />
                        }
                      </Row>
                      <Row margin="1rem 0">
                        <Typography className="BoldFont16" align="center">
                          Execution Step
                        </Typography>
                        <WithCopy copyText={data.execStep}>
                          <Chip label={`#${data.execStep}`} color="info"/>
                        </WithCopy>
                      </Row>
                      <div
                        className={
                          width > MOBILE_WIDTH
                            ? "L2TxLogCard-data-table"
                            : "L2TxLogCard-data-table-mobile"
                        }
                      >
                        <div>
                          <Row margin="1rem 0">
                            <Typography className="BoldFont16" align="left">
                              Issuer
                            </Typography>
                            <Typography
                              className="BoldFont14"
                              align="center"
                              gutterBottom
                              sx={{
                                marginBottom: 0
                              }}
                            >
                              {data.txIssuer}
                            </Typography>
                          </Row>
                          {alias && (
                            <Row
                              // style={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   setSearchParams({ search: alias });
                              // }}
                              margin="1rem 0"
                            >
                              <Typography className="BoldFont16" align="left">
                                Issuer Alias
                              </Typography>
                              <Typography
                                className="BoldFont14"
                                align="center"
                                gutterBottom
                              >
                                {alias}
                              </Typography>
                            </Row>
                          )}
                        </div>
                      </div>
                      <L2TxLogDivider variant="middle" />

                      <div
                        className="L2TxLogCard-data-table-mobile"
                        key="L2TxContainer"
                      >
                        <div
                          className={
                            width > MOBILE_WIDTH
                              ? "L2TxLogCard-data-cell"
                              : "L2TxLogCard-data-cell-mobile"
                          }
                        >
                          {data?.txLogInfo?.map((logInfo, index) => {
                            if (logInfo.__typename === "TxLoadedGql")
                              return (
                                <div key={index}>
                                  <TxLoadedSection
                                    data={logInfo}
                                    width={width}
                                    networkName={blockchain.networkName}
                                    key={index}
                                  />
                                  <L2TxLogDivider variant="middle" />
                                </div>
                              );
                            if (logInfo.__typename === "ContactExecutedGql") {
                              if (rawContextJsonObj) {
                                const element = findRawDataElement(
                                  rawContextJsonObj,
                                  logInfo
                                );
                                return (
                                  <>
                                    <TxContactExecutedSection
                                      key={index}
                                      data={
                                        logInfo as unknown as ContactExecutedGql
                                      }
                                      width={width}
                                      rawData={element}
                                    />
                                    <L2TxLogDivider variant="middle" />
                                  </>
                                );
                              }
                            }

                            if (logInfo.__typename === "TxLogErrorGql")
                              return (
                                <>
                                  <TxLogErrorSection
                                    key={index}
                                    data={logInfo}
                                    width={width}
                                  />
                                  <L2TxLogDivider variant="middle" />
                                </>
                              );
                            if (logInfo.__typename === "LegacyCwebTxLoadedGql")
                              return (
                                <>
                                  <LegacyCwebTxLoadedSection
                                    key={index}
                                    data={logInfo}
                                    width={width}
                                  />
                                  <L2TxLogDivider variant="middle" />
                                </>
                              );
                            return null;
                          })}
                        </div>
                        <RawContentModal
                          open={rawContext}
                          handleClose={handleClose}
                          data={rawTxLoaded}
                        />
                        <RawContentVisializerModal
                          handleClose={handleCloseVisializer}
                          open={rawContextVisualiser}
                          network={blockchain.networkName}
                          txId={data.txid}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </L2TxLogMuiCardContent>
      </L2TxLogMuiCard>
    </div>
  );
};

const Row = styled('div')<{ justifyBetween?: boolean, margin?: string }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: ${(props) => props.justifyBetween ? 'space-between' : 'unset'};
  margin-block: ${(props) => props?.margin ? props.margin : 'unset'};

  & > p {
    width: auto;
  }
`;

export default memo(L2TxLogCard);
