import { REACT_APP_API_ENDPOINT } from "conf";
import { request } from "graphql-request";
import { QUERY_BLOCK_BY_HEIGHT_GQL, QUERY_BLOCK_GQL, QUERY_LAST_BLOCK_GQL } from "../components/queries/block";
import { FETCH_CLAIMS_GQL } from "../components/queries/fetchClaims";
import { QUERY_L2_TX_FULL_LOG_GQL } from "../components/queries/l2TxFullLog";

export const getBlock = async (hash: String | undefined, network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(REACT_APP_API_ENDPOINT, QUERY_BLOCK_GQL, {
      hash,
      network,
    });
    return res;
  }
};

export const getFectchedClaims = async (net: any, height: number) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(REACT_APP_API_ENDPOINT, FETCH_CLAIMS_GQL, {
      net,
      height,
    });

    return {
      res: res as any,
      network: net,
    };
  }
};

export const getL2Txs = async (txid: String, network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(
      REACT_APP_API_ENDPOINT,
      QUERY_L2_TX_FULL_LOG_GQL,
      {
        txid,
        network,
      }
    );
    return res;
  }
};

export const getBlockByHeight = async (height: number, network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(
      REACT_APP_API_ENDPOINT,
      QUERY_BLOCK_BY_HEIGHT_GQL,
      {
        height,
        network,
      }
    );
    return res;
  }
};

export const getLastBlock = async (network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(
      REACT_APP_API_ENDPOINT,
      QUERY_LAST_BLOCK_GQL,
      {
        network,
      }
    );
    return res;
  }
};
