import "./styles.css";
import Typography from "@mui/material/Typography";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import { useState, memo } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";

type TargetInstancesProps = {
  data: TargetInstance[];
};

const TargetInstances = (props: TargetInstancesProps) => {
  const { data } = props;
  const [expanded, setExpanded] = useState(Array(data?.length).fill(false));

  const handleExpandChange = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index]; // Toggle the value
    setExpanded(newExpanded); // Update state
  };
  function Row({ row, index }: { row: TargetInstance; index: number }) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#20214a",
          borderRadius: "6px",
          margin: "10px",
        }}
      >
        <TableCell className="operationCollapseCell" sx={{ width: "100%" }}>
          <div>
            <div className="grid_container">
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Contract Instance Alias
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.alias}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Contract Instance Id
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.instance_id}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className="operationCollapseCell"
              onClick={() => handleExpandChange(index)} // Directly invoke handleExpandChange here
              style={{ cursor: "pointer" }} // Add cursor pointer for better UX
            >
              <IconButton aria-label="expand row" size="small">
                {expanded[index] ? (
                  <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
                )}
              </IconButton>
              <Typography className="BlueFont14">Parameters</Typography>
            </div>
          </div>
        </TableCell>
        <TableCell
          sx={{ width: "100% !important" }}
          className="operationCollapseCell"
        >
          <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
            <div className="grid_container">
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  L1 Contract Address
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.l1_contract_address ||
                      "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  L1 Type
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.l1_type !== undefined
                      ? row?.parameters?.content?.l1_type
                      : "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Owner
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.owner || "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Owner Minimum Fee
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.owner_min_fee || "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Owner Percentage Fee
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.owner_percentage_fee ||
                      "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Shard
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.shard || "not provided"}
                  </Typography>
                </div>
              </div>
              <div className="grid_cell">
                <Typography className="BoldWhiteFont14" align="left">
                  Logs
                </Typography>
                <div className="L2TxId-row">
                  <Typography className="BoldFont14" align="center">
                    {row?.parameters?.content?.logs?.join(", ") ||
                      "not provided"}
                  </Typography>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </div>
    );
  }

  return (
    <div>
      {data.map((target, index) => (
        <Row row={target} index={index} key={index} /> // Add unique key to the Row component
      ))}
    </div>
  );
};

export default memo(TargetInstances);
