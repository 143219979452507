import { cloneElement, forwardRef, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { Fade, Tooltip } from "@mui/material";
import copyToClipboard from "utils/copyToClipboard";
import React from "react";
import { delay } from "lodash";

type WithCopyProperties = PropsWithChildren<{
    onCopy?: () => void;
    copyText: string | number;
}>

export const WithCopy = forwardRef<HTMLDivElement, WithCopyProperties>(({children, onCopy, copyText}) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopyClick = useCallback(() => {
        const onCopyBatched = () => {
            setIsCopied(true);
            onCopy?.();
        }

        copyToClipboard(String(copyText), onCopyBatched);
    }, [copyText, onCopy]);

    useEffect(() => {
        const backAnimation = async () => {
            if (!isCopied) return;
            await delay(() => setIsCopied(false), 3_000);
        }

        void backAnimation();        
    }, [isCopied])

    return (
        <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#4f4f72 !important",
              padding: "0px !important",
              marginTop: "-1px !important",
            },
          },
        }}
        disableFocusListener
        TransitionComponent={Fade}
        className="Tooltip-hash-container"
        placement="bottom"
        title={<div id="TxId-tooltip">{isCopied ? "Copied" : copyText}</div>}
      >
        {
            React.isValidElement(children) 
                // @ts-expect-error do not use react portal as child
                ? cloneElement(children, { onClick: onCopyClick }) 
                : <div onClick={onCopyClick}>{children}</div>
        }
      </Tooltip>
    )
});

WithCopy.displayName = 'WithCopy';