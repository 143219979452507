import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

type CardAdditionalMenuProperties = {
    items: Array<{label: string, onClick: () => void}>
}

export default function CardAdditionalMenu({ items }: CardAdditionalMenuProperties) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (callbackFn?: () => void) => {
    callbackFn?.();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="icon-button"
        aria-controls={open ? 'icon-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="icon-menu"
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((option, index) => (
          <MenuItem key={index} onClick={() => handleClose(option.onClick)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
