import { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import BlockHashIcon from "assets/Icons/block-hash.svg";
import BlueBlockHashIcon from "assets/Icons/blue-block-hash.svg";
import { WithCopy } from "shared/components";

type CopyIconProps = {
  data: string;
};

const CopyIcon = (props: CopyIconProps) => {
  const { data } = props;

  const [BlockIcon, setBlockIcon] = useState(false);

  const handleLeave = () => {
    setBlockIcon(false);
  };

  return (
      <WithCopy copyText={data} onCopy={handleLeave}>
        <IconButton
          onMouseEnter={() => setBlockIcon(true)}
          onMouseLeave={handleLeave}
          sx={{ p: "0px 0px 0px 10px" }}
          aria-label="row"
        >
          <img
            className="Blockhash-icon"
            src={BlockIcon ? BlueBlockHashIcon : BlockHashIcon}
            alt={"Block hash"}
          />
        </IconButton>
      </WithCopy>
  );
};

export default memo(CopyIcon);
